import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css'
import CloseIcon from '@mui/icons-material/Close';

const container = {
    hidden: { opacity: 0, },
    show: {

        opacity: 1,
        transition: {
            staggerChildren: 0.2
        }
    }
}
const item = {
    hidden: { opacity: 0, x: -50 },
    show: { opacity: 1, x: 0, }
}
const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    console.log(element, "3657394857834")
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

const ExpandableMenu = ({ closeFunction }) => {
    return (
        <div style={{ width: '100vw', height: '100vh', zIndex:2000, position:"absolute" }}>
            <AnimatePresence>
                <motion.div className='menuWrapper'>
                    <div className='closeIcon' onClick={(event) => closeFunction(event)}>
                        <CloseIcon />
                    </div>
                    <motion.ul variants={container} animate={"show"} initial={"hidden"} exit={"hidden"}>
                        <motion.li className='menu1' variants={item} onClick={(event) => {
                            scrollToElement('bio')
                            closeFunction(event)
                            }}>home</motion.li>
                        <motion.li className='menu2' variants={item} onClick={(event) => {
                            scrollToElement('merch')
                            closeFunction(event)
                            }}>merch</motion.li>
                        <motion.li className='menu3' variants={item} onClick={(event) => {
                            scrollToElement('music')
                            closeFunction(event)
                            }}>music</motion.li>

                    </motion.ul>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

export default ExpandableMenu;
