import React from 'react';
import './App.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LandingPage from './LandingPage';
import logo from './images/logo.png'
import MerchApp from './merchApp/MerchApp';
import banner from './images/pinkline.gif'
import { CartProvider, useCart } from './merchApp/CartContext';
import { CircularProgress } from '@mui/material';
import ExpandableMenu from './ExpandableMenu';
import { useState } from 'react';
import { motion } from 'framer-motion'
import MenuIcon from '@mui/icons-material/Menu';





import MusicApp from './musicApp/MusicApp';
import axios from 'axios';
import apiClient from './apiClient';
import { ExitToApp } from '@mui/icons-material';







function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (event) => {
    console.log(isMenuOpen, "9746374658376485734")
    setIsMenuOpen(!isMenuOpen);

  };

  const NavigationBar = () => {
    const API_URL = `http://Cr1tterLoadMachine-1725031004.us-east-2.elb.amazonaws.com`;
    const { cart, triggerAnimation, addingToCart, cartCount } = useCart();



    const cartLink = () => {

      // Open a new window immediately with a temporary URL
      const newWindow = window.open('about:blank', '_blank');

      axios
        .post(
          `https://api.cr1tter.net/Products/ProcessCheckout`,
          {productId:'', cartId: `${cart}`, quantity:0, size:'NA' }, // Send the data as a JSON object
          {
            headers: {
              "Content-Type": "application/json", // Explicitly set the Content-Type header to 'application/json'
            },
          }
        )
        .then((response) => {
          // Update the new window's URL with the actual response data
          if (newWindow) {
            newWindow.location.href = response.data;
          } else {
            // If the new window was blocked, show an error message or handle the situation accordingly
            console.error("Pop-up blocked");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    return (

      <nav className="navbar">

        <div className='shoppingCartWrapper' >
          <ShoppingCartIcon className="nav-icon" style={{ height: "unset" }} onClick={() => cartLink()} />
          <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
            {cartCount === 0 ? '' : cartCount}
          </div>
          <img src={logo} alt="Logo" className="nav-logo" />

        </div>
        <motion.div className='hamburger' onClick={(event) => toggleMenu(event)}>
          <MenuIcon />
        </motion.div>

      </nav>
    );
  };

  return (

    <CartProvider>
      <div className="App">
        
          <>
          {isMenuOpen ? <ExpandableMenu closeFunction={((event)=>{toggleMenu(event)})} /> : <></>}
            <NavigationBar />
            <div id="bio">
              <LandingPage className='landingPage' />

            </div>

            <div className="sectionDiv" />
            <div id="merch" className='merch'>
            <MerchApp  />

            </div>

            <div className="sectionDivTat" />
            <div id="music">
              <MusicApp />

            </div>
            <div className='footerWrapper'>
              <div className='footer'></div>
            </div>
          </>

        





      </div>
    </CartProvider>



  );
}

export default App;
