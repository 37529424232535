import React, { useEffect, useState } from 'react';
import manager from '../images/manger.png'
import './merch.css'
import MerchItem from './merchItem';
import cd from '../images/cd.png'

import shopifyClient from './shopify';
import { useCart } from './CartContext';
import apiClient from '../apiClient';
import CircularProgress from '@mui/joy/CircularProgress';

import axios from 'axios';


function MerchApp() {
    const [loading, setLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const { addToCart, setCart } = useCart();
    const fetchProducts = async () => {
        axios.get(`https://api.cr1tter.net/Products`)
            .then((response) => {

                console.log(response, "76283746872368");
                setProducts(response.data);
                setLoading(false)
            })
            .catch((error) => {
                console.error('4283742894729873984:', error);
            });

    };

    useEffect(() => {
        setLoading(true)
        fetchProducts();
    }, []);
    return (
        

        <div className='merchAppWrapper'>
            <div className='titleWrapper'>
                <div className='titleLeftMerch'>merch</div>
                <div className='titleCenter'>merch</div>
                <div className='titleRightMerch'>merch</div>
            </div>
            <div className='imageContainerMerch'>
                <img className='managerMerch' src={manager}>

                </img>
                <img className='cdMerch' src={cd}>

                </img>

            </div>
            {loading ? <div style={{height:'18rem', }}><CircularProgress/></div> :
            
                <div className='merchCollectionWrapper'>
                {products.map((item)=><MerchItem product={item}></MerchItem>)}
                
                </div>}
                {products.length === 0 && <div className='comingSoon'>No products available.</div>}
                

        
            






        </div>
    )
}
export default MerchApp;

/*

            */