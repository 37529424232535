import React, { useState } from 'react';
import YouTube from 'react-youtube';
import './musicApp.css';

function YoutubeVideoPlayer(props) {
    const { videoLink } = props;
    const [isHovered, setIsHovered] = useState(false);
    const screenWidth = window.innerWidth;
    console.log(screenWidth <= 480 ? '50rem' : '300vh', "6327865348756")
    const [isClicked, setIsClicked] = useState(false);

    // Extract the video ID from the provided YouTube link
    const videoId = videoLink.split('v=')[1]?.split('&')[0];

    const opts = { height: screenWidth <= 480 ? '300rem' : '500rem', width: screenWidth <= 480  ? '300rem' : '700rem', playerVars: { autoplay: 1, modestbranding: 1 } };
    const handlePlayerReady = (event) => {
        // The YouTube player is ready
        event.target.playVideo();
      };
    
    
    return videoId ? (
        <div className='musicVideoWrapper'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setIsClicked(!isClicked)}>
            <div className={`musicVideoContainer`}>
                <YouTube videoId={videoId} opts={opts} onReady={handlePlayerReady}/>
            </div>
        </div>
    ) : (
        <p>Please provide a valid YouTube video link.</p>
    );

    
}

export default YoutubeVideoPlayer;
