import React from "react"
import './LandingPage.css'
import gameboy from './images/gameboy.png'
import ipod from './images/ipod.png'
import manger from './images/manger.png'
import heart from './images/heart.png'
import gradient from './images/critterGradient.png'
import rotatingStar from './images/rotating-star.gif'
import link from './images/linkgif.gif'
import gifHeart from './images/99-1.gif'
import critterNet from './images/critterTat.png'
import logo from './images/logo.png'
import cd from './images/cd.png'


function LandingPage(){
    return(
        <div className="content">
        <div className='textContainer'>
          <div className='flexText'>
            <div className='welcomeText'>
              welcome to the
            </div>
          </div>
          <div className='flexText'>
            <div className='titletext'>
              cr1tter.n3t
              
            </div>
          </div>
          
        </div>

        <div className='imagesContainer'>
          <div className='flexImage'>
            <img className='gameboy' alt="gameboy" src={gameboy}></img>
          </div>
          <div className='flexImage'>
            <img className='ipod' alt="ipod" src={ipod}></img>
          </div>
          <div className='flexImage'>
            <img className='manger' alt="manger" src={manger}></img>
          </div>
          <div className='flexImageBehind'>
            <img className='gradient' alt='crittergradient' src={gradient}></img>
          </div>
          <div className="fixedImage">
            <img className="critterNet" src={critterNet}>
          </img>
          </div>
          <div className="flexImage">
            <img className="animatedHeart" src={gifHeart}>
            </img>
          </div>
          <div className='flexImage'>
            <img className='heartBalloon' alt="star" src={rotatingStar}></img>
          </div>
          <div className='flexImage'>
            <img className='linkGif' alt="link" src={link}></img>
          </div>
        </div>
        <div className='bioContent'>
          <div className='bioTitle'>
          Hi:3 
          </div>
          <div className='bioText'>
Welcome to the Cr1tter.n3t !!! 
Buy cool stuff 
Watch cool video 
And listen to great music . 
Shoutout to everyone making this shit possible it’s Cr1tter season forever more &lt;3          </div>
          

        </div>
          <div className="flexImage">
            <img className="critterNet" src={critterNet}>
            </img>
          </div>
        
        
      </div>
    )
}

export default LandingPage;

