import { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};



export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const [cartId, setCartId]=useState([]);
  const [addingToCart, setAdding] =useState(false);

  const triggerAnimation = () =>{
    setAdding(!addingToCart);
  }

  const addToCart = (product) => {
    setCart(product)
    setCartCount(cartCount + 1);
  };

  

  const clearCart = () => {
    setCart([]);
  };

  const value = {
    cart,
    setCart,
    cartCount,
    cartId,
    setCartId,
    addToCart,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
