
import { useCart } from './CartContext';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../../src/images/cd.png'
import React, { useState } from 'react';
import AnimatedImage from './animatedCartItem';
import cd from '../images/cd.png'
import './merch.css'
import { CircularProgress } from '@mui/joy';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';



function MerchItem({ product }) {
    const { cart, addToCart } = useCart();
    const [addingToCart, setAdding] = useState(false)
    const [showAnimation, setShowAnimation] = useState(false);
    const [selectedSize, setSelectedSize] = useState("Small");
    const API_URL = `http://Cr1tterLoadMachine-1725031004.us-east-2.elb.amazonaws.com`;

    const handleAddToCartClick = (product) => {
        addToCart(product);
    };

    const sendToCart = async () => {
        setAdding(true);
        axios.post(
            `https://api.cr1tter.net/Products/CreateCart`,
            { productId: `${product.id}`, cartId: `${cart}`, quantity: 1, size: selectedSize }, // Send the data as a JSON object
            {
                headers: {
                    "Content-Type": "application/json", // Explicitly set the Content-Type header to 'application/json'
                },
            }
        )
            .then((response) => {
                console.log(response, "64378568736875683746")
                addToCart(response.data);
                setAdding(false)
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    return (

        <div className='merchItemWrapper'>


            <div className='merchItemOuter'>
                <div className='merchItemInner'>
                    <img src={product.featuredImage?.transformedSrc} alt={product.title} className='merchItemImage' />
                </div>
            </div>
            <div className='merchTitleWrapper'>
                <div className='merchTitle'>
                    {product.title}
                </div>
            </div>
            <div className='merchPriceWrapper'>
                <div className='merchPrice'>
                    {product.priceRange.minVariantPrice.amount}
                </div>
            </div>
            {addingToCart ? <CircularProgress /> :
                <div className='addToCartWrapper'>
                    <FormControl size='small'>
                        <InputLabel sx={{color:'white', fontFamily:'DigiBop'}} id="demo-simple-select-label">Size</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Size"
                            onChange={(e) => setSelectedSize(e.target.value)}
                            variant='outlined'
                            value={selectedSize}
                            sx={{ fontFamily:"DigiBop", color: 'white' , backgroundColor: 'black', borderRadius: '50rem', width: '300px', border: '1px solid grey'}}
                        >
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"Small"}>Small</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"Medium"}>Medium</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"Large"}>Large</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"X-Large"}>X-Large</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"2X-Large"}>2X-Large</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"3X-Large"}>3X-Large</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"4X-Large"}>4X-Large</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"5X-Large"}>5X-Large</MenuItem>
                            <MenuItem sx={{fontFamily:'DigiBop'}} value={"7X-Large"}>7X-Large</MenuItem>
                        </Select>
                    </FormControl>
                    <button onClick={() => sendToCart(product)} className="addToCartButton">
                        Add to Cart
                    </button>


                </div>
            }


        </div>
    )
}
export default MerchItem;