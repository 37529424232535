import React, { useState } from 'react';
import './musicApp.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function SpinningDisk({ imageSrc }) {
    const videoId = imageSrc.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?\/)|.*[?&]v=)|youtu\.be\/)([^"&?/ %]{11})/i)[1];

  // Choose the desired thumbnail resolution (use one of the formats mentioned earlier)
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  return (
    <div className='spinningDiskWrapper'>
      <div className='cdWrapper'>
        {
          /*
          <div className='playButton'>
          <PlayArrowIcon style={{fontSize: '500px'}}/>

        </div>
          */
        }
        
        <div
          className='cdImageMusic'
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
        ></div>
        <div className='innerCdHole' />
      </div>
    </div>
  );
}

export default SpinningDisk;

