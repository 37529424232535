import React, { useState } from 'react';
import YoutubeVideoPlayer from './YoutubePlayer';
import './musicApp.css';
import SpinningDisk from './SpinningDisk';
import { motion, AnimatePresence } from 'framer-motion';
import { PlayArrow } from '@mui/icons-material';
import { Pause, Stop } from '@mui/icons-material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import kirby from '../images/kirby-jamming-pink.gif'



function MusicApp({ songs, videos }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoPlaying, setVideoPlaying] = useState(-1);

  const [direction, setDirection] = useState("");
  const videoLinks = [
    {name: "hand me downs", link: 'https://www.youtube.com/watch?v=YMuG3COt0Vo'},
    {name: "bugs", link: 'https://www.youtube.com/watch?v=wjUpTsUhd9k'},
    {name: "sickly", link: 'https://www.youtube.com/watch?v=qyxCSLK2VQc'}
  ];
  const cdVariants = {
    enter: (direction) => ({
      //x: direction > 0 ? 100 : -100,
      opacity: 0,
      y: 100,
    }),
    center: {
      zIndex: 1,
      //x: 0,
      opacity: 1,
      y:0,
    },
    exit: (direction) => ({
      zIndex: 0,
      //x: direction < 0 ? 100 : -100,
      opacity: 0,
      y:100,
    }),
    titleEnter: (direction) => ({
      //x: direction > 0 ? 100 : -100,
      opacity: 1,
      y: 100,
      transition:{
        type: 'tween',
        duration: 0.5,
        ease: 'easeIn',
        delay: 0.2,
      }
    }),
    titleCenter: {
      zIndex: 1,
      //x: 0,
      opacity: 1,
      y:0,
    },
    titleExit: (direction) => ({
      zIndex: 0,
      //x: direction < 0 ? 100 : -100,
      opacity: 0,
      y:100,
      transition:{
        type: 'tween',
        duration: 1,
        ease: 'easeOut',
        delay: 0.2,
      }
    }),
  };



  const handlePrevious = () => {
    setDirection(-1);
    setVideoPlaying(-1)
    setCurrentIndex(currentIndex-1 < 0 ? videoLinks.length-1 : currentIndex-1)
  };

  const handleNext = () => {
    setDirection(1);
    setVideoPlaying(-1)
    setCurrentIndex((currentIndex + 1) % videoLinks.length);
    console.log(currentIndex + 1, "643785634875")
  };

  return (
    <div className='musicAppWrapper'>
      <img className='kirby' src={kirby}></img>
      <img className='kirby2' src={kirby}></img>
      <div className='titleWrapper'>
      <div className='titleLeftMusic'>music</div>
                <div className='titleMusic'>music</div>
                <div className='titleRightMusic'>music</div>
      </div>
      <div className='playButton'>
        
        <div className='playButtonText' onClick={()=>{videoPlaying === -1 ? setVideoPlaying(currentIndex): setVideoPlaying(-1)}}>
          
          {videoPlaying !== -1 ? 
            <>
            <Stop className='innerStop'/>
            </>:
            <>
            <PlayArrow className='innerPlay'/>
            </>
          }
        
        </div>
      </div>
      <div className='music'>
      <motion.button
          className={videoPlaying !== -1 ? 'leftArrowPlaying' : 'arrow leftArrow'}
          onClick={handlePrevious}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
        >
          <SkipPreviousIcon/>
        </motion.button>

        <AnimatePresence custom={direction}>
              
              {videoPlaying !== -1 ? 
                <YoutubeVideoPlayer className="youtubeVideoPlayer" videoLink={videoLinks[currentIndex].link}/>:
                <motion.div
                key={videoLinks[currentIndex].link}
                className='motionDiv'
                custom={direction}
                variants={cdVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30},
                  opacity: { duration: 0.2 },
                }}
              >
                
                <SpinningDisk imageSrc={videoLinks[currentIndex].link} />

                
              </motion.div>

              }
        </AnimatePresence>


        <motion.button
          className={videoPlaying !== -1 ? 'rightArrowPlaying' : 'arrow rightArrow' }
          onClick={handleNext}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
        >
          <SkipNextIcon/>
        </motion.button>
        {videoPlaying !== -1 ? 
          <></>:
          <motion.div className='motionDiv'
              variants={cdVariants}
              key={videoLinks[currentIndex].name}
              custom={direction
              }
              initial="titleEnter"
              animate="titleCenter"
              exit="titleExit">
                <div className='songTitle'>
                {videoLinks[currentIndex].name}

                </div>
                </motion.div>

        }
        
      </div>
    </div>
  );
}

export default MusicApp;
